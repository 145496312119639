import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { ref, computed } from 'vue'

export const useSearchFiltersStore = defineStore('searchFiltersStore', () => {
  // Utilisation de localStorage pour persister les filtres
  const selectedDiscipline = useLocalStorage<string>('selectedDiscipline', '')
  const priceOrder = useLocalStorage<'asc' | 'desc' | 'all'>(
    'priceOrder',
    'all',
  )
  const consultationType = useLocalStorage<'remote' | 'in-person' | 'all'>(
    'consultationType',
    'all',
  )
  const opinionOrder = useLocalStorage<'asc' | 'desc' | 'all'>(
    'opinionOrder',
    'all',
  )
  const sortBy = useLocalStorage<
    'proximity' | 'price.asc' | 'price.desc' | 'opinions.asc' | 'opinions.desc'
  >('sortBy', 'proximity')

  // État pour savoir si les filtres sont dans leur état initial
  const areFiltersDefault = computed(() => {
    return (
      selectedDiscipline.value === '' &&
      priceOrder.value === 'all' &&
      consultationType.value === 'all' &&
      opinionOrder.value === 'all' &&
      sortBy.value === 'proximity'
    )
  })

  // Méthode pour réinitialiser les filtres
  const resetFilters = () => {
    selectedDiscipline.value = ''
    sortBy.value = 'proximity'
    priceOrder.value = 'all'
    consultationType.value = 'all'
    opinionOrder.value = 'all'
  }

  return {
    selectedDiscipline,
    priceOrder,
    sortBy,
    consultationType,
    opinionOrder,
    areFiltersDefault,
    resetFilters,
  }
})
