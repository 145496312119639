import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api'
import { ProfileInformationData } from '@/api/profile/profile.d'
import MessageService from '@/components/feedback/message/messageService'
import { useI18n } from 'vue-i18n'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'

const httpClient = createHttpClient()

export const useProfileStore = defineStore('profile', () => {
  const profiles: Ref<ProfileInformationData[]> = ref([])
  const isUpdated: Ref<Boolean> = ref(false)
  const { t } = useI18n()
  const totalProfile = ref(0)
  const createNewProfils = ref(false)
  const loadProfile = ref(false)

  // Fonction pour charger les profils depuis le cache (localStorage)
  const loadFromCache = () => {
    const cachedData = localStorage.getItem('profiles')
    if (cachedData) {
      try {
        profiles.value = JSON.parse(cachedData)
      } catch (error) {
        console.error('Failed to parse cached profile data:', error)
      }
    }
  }

  // Fonction pour remplir les profils en récupérant depuis l'API
  const fillProfiles = async () => {
    try {
      // Si les profils sont déjà chargés en mémoire, ne pas refaire la requête
      if (profiles.value.length > 0) {
        return
      }

      const res: ApiResponse<ProfileInformationData[]> = await getProfiles()
      if (res.status !== 200) throw Error(JSON.stringify(res.data))

      // Remplir les profils dans le state
      profiles.value = res.data

      // Mettre à jour le cache
      localStorage.setItem('profiles', JSON.stringify(profiles.value))
      isUpdated.value = true

      // Vérification et mise à jour du profil par défaut
      setDefaultProfileIfNotExist()
    } catch (error) {
      console.error('Failed to fetch profiles:', error)
      MessageService.error(t('profile.errorGetProfile'))
    }
  }

  // Fonction pour rafraîchir les profils (force la récupération depuis l'API)
  const refreshProfiles = async () => {
    try {
      const res: ApiResponse<ProfileInformationData[]> = await getProfiles()
      if (res.status !== 200) throw Error(JSON.stringify(res.data))

      // Mettre à jour les profils dans le state et le cache
      profiles.value = res.data
      localStorage.setItem('profiles', JSON.stringify(profiles.value))
      isUpdated.value = true

      // Vérification et mise à jour du profil par défaut
      setDefaultProfileIfNotExist()
    } catch (error) {
      console.error('Failed to refresh profiles:', error)
      MessageService.error(t('profile.errorGetProfile'))
    }
  }

  // Fonction pour initialiser les profils (charge depuis cache puis API si nécessaire)
  const initProfiles = async () => {
    try {
      loadFromCache()

      if (isUpdated.value == false) {
        await fillProfiles()
      }
    } catch (error) {
      console.error('Failed to initialize profiles:', error)
    }
  }

  // Fonction pour définir le profil par défaut s'il n'existe plus
  const setDefaultProfileIfNotExist = () => {
    const dialogTimeSlotStore = useDialogTimeSlotStore()
    if (dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress) {
      if (
        !dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.profile ||
        !profiles.value.some(
          profile =>
            profile.id ===
            dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.profile.id,
        )
      ) {
        dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.profile =
          profiles.value[0]
      }
    }
  }

  return {
    profiles,
    initProfiles,
    refreshProfiles,
    setDefaultProfileIfNotExist,
    totalProfile,
    createNewProfils,
    loadProfile,
  }
})

// Fonction pour récupérer les profils depuis l'API
const getProfiles = async (): Promise<
  ApiResponse<ProfileInformationData[]>
> => {
  const response = await httpClient.get<ProfileInformationData[]>('/profile/')
  return {
    status: response.status,
    data: response.data,
  }
}
